import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../css/Report.css";
import "../css/Animation.css";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import ChecklistItem from "./CheckListItem";
import PDFViewer from "./PDFViewer.js"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Report({ token,  isDark, pdfPath }) {
  const navigate = useNavigate(); // Hook for navigation
  const [report, setReport] = useState({
    checkList: {},
    matchingDetails: {},
    summary: "No report data available.",
    suggestions: "",
  }); //Default report

  const [animatedScore, setAnimatedScore] = useState(0); // State to animate the score

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const storedReportData = localStorage.getItem("report");

    if (storedReportData && token) {
      setReport(JSON.parse(storedReportData));
    } else {
      navigate("/");
    }
  }, [token]);

  // Animate the score count-up effect
  useEffect(() => {
    if (report.matchingDetails.overallScore) {
      let score = 0;
      const interval = setInterval(() => {
        if (score < report.matchingDetails.overallScore) {
          score += 1; // Increase the score gradually
          setAnimatedScore(score);
        } else {
          clearInterval(interval); // Clear interval when target score is reached
        }
      }, 40); // Adjust speed of count-up
    }
  }, [report.matchingDetails.overallScore]);

  return (
    <section className="report-page" ref={componentRef}>
      <h1 className="animate__animated animate__fadeInDown">
        {`${report.checkList.name}'s Report`}
      </h1>
      <section className="report-sections">
        {/* Total Score Section with Animation */}
        <div className="overall-report-session" id={isDark === "true" ? "dark-overall-report-session" : "null"}>
          <div className="total-score">
            <div
              className="progress-circle"
              style={{ "--progress": `${animatedScore}%` }}
            >
              <h1>{animatedScore}</h1> {/* Display animated score */}
              <p>Relevant Score</p>
            </div>
          </div>
          <p className="puff-in-center">{report.summary}</p>
          {/* Overall Report */}
          <div className="overall-report">
            <p className="readability puff-in-center">
              <strong>Readability:</strong>
              <span>{" " + report.matchingDetails.readability || "Poor"}</span>
            </p>
            <p className="estimated-time puff-in-center">
              <strong>Estimated Reading Time:</strong>{" "}
              <span>
                {report.matchingDetails.estimatedReadingTime || "N/A"}
              </span>
            </p>
            <p className="action-verb puff-in-center">
              <strong>Action Verbs Used:</strong>{" "}
              <span>{report.matchingDetails.actionVerbsUsed || "N/A"}</span>
            </p>
          </div>
        </div>
        {/* Checklist Section */}
        <section className="checklist-container" id={isDark === "true" ? "dark-checklist-container" : "null"}>
          <h2>Checklist</h2>
          <div className="checklists">
            <ChecklistItem label="Name" value={report.checkList.name} />
            <ChecklistItem label="Job Role" value={report.checkList.jobRole} />
            <ChecklistItem label="Mobile" value={report.checkList.number} />
            <ChecklistItem label="Email" value={report.checkList.email} />
            <ChecklistItem label="LinkedIn" value={report.checkList.linkedIn} />
            <ChecklistItem label="Location" value={report.checkList.location} />
            <ChecklistItem label="Portfolio" value={report.checkList.portfolio} />
            <ChecklistItem label="Summary" value={report.checkList.summary && "Yes"} />
            <ChecklistItem label="Awards And Recognition" value={report.checkList.awardsAndRecognition} />
            <ChecklistItem label="Education" value={report.checkList.education} />
            <ChecklistItem label="Projects" value={report.checkList.projects} />
            <ChecklistItem label="Certifications" value={report.checkList.certifications} />
            <ChecklistItem label="Languages Known" value={report.checkList.languagesKnown} />
          </div>
        </section>
        {/* Other Sections */}
        <div className="other-section">
          <section className="suggestions-section animate__animated animate__fadeInUp checklist" id={isDark === "true" ? "dark-suggestions-section" : "null"}>
            <h2>
              Suggestions (Enhance your resume by adding the following details)
            </h2>
            {report.matchingDetails.educationMissing && (
              <p>
                <strong>Education:</strong>{" "}
                <span>{report.matchingDetails.educationMissing}</span>
              </p>
            )}
            {report.matchingDetails.experienceMissing && (
              <p>
                <strong>Experience:</strong>{" "}
                <span>{report.matchingDetails.experienceMissing}</span>
              </p>
            )}
            {report.matchingDetails.keywordsMissing && (
              <p>
                <strong>Keywords:</strong>{" "}
                <span>{report.matchingDetails.keywordsMissing}</span>
              </p>
            )}
            {report.matchingDetails.skillsMissing && (
              <p>
                <strong>Skills:</strong>{" "}
                <span>{report.matchingDetails.skillsMissing}</span>
              </p>
            )}
            <p>
              <strong>Suggestion:</strong>{" "}
              <span>{report.suggestions || "No suggestions available."}</span>
            </p>
          </section>
          {/* Summary Section */}
          {/* <section className="summary-section animate__animated animate__fadeInUp checklist">
            <h2>Summary of your resume</h2>
            <p>{report.summary || "No summary available."}</p>
          </section> */}
        </div>
      </section>
      <Link to="/">
        <button className="btn">Recheck</button>
      </Link>
      <button className="btn" onClick={handlePrint}>Save report</button>
      {/* <PDFViewer pdfPath={pdfPath}/> */}
    </section>
  );
}

export default Report;
